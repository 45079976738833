<template>
	<v-flex style="margin-bottom: 20px" shrink>
		<v-layout align-center row>
			<v-flex style="width: 60px">
				<v-text-field v-model="quantity" hide-details :label="$t('offers.duration')" min="0" type="number" @input="formatDuration" />
			</v-flex>
			<v-flex pl-1 style="width: 80px">
				<v-select v-model="selectedType" hide-details :label="$t('offers.unity')" :items="types" @change="formatDuration" />
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'VDuration',
	props: {
		value: {
			required: true,
			type: Number,
			default: () => -1
		}
	},
	data: function () {
		return {
			quantity: null,
			selectedType: 'hour',
			types: [
				{ text: this.$t('hours'), value: 'hour' },
				{ text: this.$t('days'), value: 'day' }
			]
		}
	},
	created: function () {
		if (this.value >= 0 && this.value % 24 == 0 && this.value != 0) {
			this.selectedType = 'day'
			this.quantity = this.value / 24
		} else if (this.value >= 0) {
			this.quantity = this.value
		}
	},
	methods: {
		formatDuration: function () {
			const value = this.quantity
			if (!value) {
				this.$emit('input')
			} else {
				if (!this.selectedType) {
					this.selectedType = 'hour'
				}
				if (this.selectedType == 'hour') {
					this.$emit('input', parseInt(this.quantity))
				} else {
					this.$emit('input', parseInt(this.quantity * 24))
				}
			}
		}
	}
}
</script>
